html, body {
  width: 100%;
  height: 100%;
} 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#main {
  flex: 1;
  position: relative;
}

.rag-red {
  color: rgb(192, 0, 0);
  font-weight: bold;
}
.rag-green {
  color: rgb(112, 173, 71);
  font-weight: bold;
}
.rag-yellow {
  background-color: rgb(255, 192, 0);
}
