.pageLoader {
  position: fixed;
  display: flex;
  padding-top: 2em;
  background-color: #000000a3;
  z-index: 999998;
  transition: transform .35s ease-out,-webkit-transform .35s ease-out;
  color: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}